import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

import FlexBetween from 'components/flexbetweenbox'

const Header = ({ title, subTitle, action }) => {
  const theme = useTheme()
  return (
    <FlexBetween mb='20px'>
      <Box>
        <Typography
          variant='h2'
          color={theme.palette.secondary[100]}
          fontWeight='bold'
          sx={{
            mb: '5px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant='h5'
          color={theme.palette.secondary[300]}
        >
          {subTitle}
        </Typography>
      </Box>
      {action}
    </FlexBetween>
  )
}

export default Header
