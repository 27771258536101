import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

import { useLoginMutation } from 'store/slices/auth/authApiSlice'
import { selectCurrentUser, setUser } from 'store/slices/auth/authSlice'
import { notify } from 'utils/notify'
import { Box, Button, TextField, Typography, useTheme } from '@mui/material'

const Auth = () => {
  const theme = useTheme()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const from = location?.state?.from || '/'

  const [login, { data, isError, error, isSuccess, isLoading }] = useLoginMutation()

  const user = useSelector(selectCurrentUser)

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      await login(data).unwrap()
    } catch (error) {
      const { data } = error
      notify(data?.message, 'error')
      data?.errors?.map((err) =>
        setError(err.path, {
          message: err.msg,
        }),
      )
    }
  }

  useEffect(() => {
    if (isSuccess) {
      const { accessToken, user: userData } = data
      dispatch(setUser({ user: userData, accessToken }))
      localStorage.setItem('accessToken', accessToken)
      navigate(from, { replace: true })
    }
    if (isError) {
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess])

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      gap={3}
      height='100dvh'
      component='form'
    >
      <Typography
        variant={'h4'}
        fontWeight={'bold'}
      >
        MovieHeroAdmin
      </Typography>
      <Typography>Авторизация</Typography>
      <Controller
        name='email'
        control={control}
        render={({ field }) => (
          <TextField
            required
            error={errors['email'] ? true : false}
            helperText={errors['email']?.message}
            id='outlined-required'
            label='Почта'
            {...field}
          />
        )}
      />
      <Controller
        name='password'
        control={control}
        render={({ field }) => (
          <TextField
            required
            error={errors['password'] ? true : false}
            helperText={errors['password']?.message}
            id='outlined-required'
            label='Пароль'
            {...field}
          />
        )}
      />
      <Button
        variant='contained'
        size='large'
        sx={{
          color: `${theme.palette.secondary[200]}`,
        }}
        type='submit'
      >
        Войти
      </Button>
    </Box>
  )
}

export default Auth
