import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Subscriptions,
  ChevronLeft,
  ChevronRightOutlined,
  SettingsOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
  AccountBalanceWallet,
  LocalPlay,
  VideoSettings,
} from '@mui/icons-material'

import FlexBetween from 'components/flexbetweenbox'
import imageUrl from 'assets/profile.jpeg'

const navItems = [
  {
    text: 'Панель',
    link: 'dashboard',
    icon: <HomeOutlined />,
  },
  {
    text: 'Для клиентов',
    link: '',
    icon: null,
  },
  {
    text: 'Пользователи',
    link: 'users',
    icon: <Groups2Outlined />,
  },
  {
    text: 'Тарифы',
    link: 'rates',
    icon: <LocalPlay />,
  },
  {
    text: 'Подписки',
    link: 'subscriptions',
    icon: <Subscriptions />,
  },
  {
    text: 'Видео',
    link: 'videos',
    icon: <VideoSettings />,
  },
  // {
  //   text: 'Transactions',
  //   icon: <ReceiptLongOutlined />,
  // },
  // {
  //   text: 'Geography',
  //   icon: <PublicOutlined />,
  // },
  // {
  //   text: 'Overview',
  //   icon: <PointOfSaleOutlined />,
  // },
  // {
  //   text: 'Daily',
  //   icon: <TodayOutlined />,
  // },
  // {
  //   text: 'Monthly',
  //   icon: <CalendarMonthOutlined />,
  // },
  // {
  //   text: 'Breakdown',
  //   icon: <PieChartOutlined />,
  // },
  // {
  //   text: 'Статистика',
  //   link: '',
  //   icon: null,
  // },
  // {
  //   text: 'Доход',
  //   link: 'balance',
  //   icon: <AccountBalanceWallet />,
  // },
  // {
  //   text: 'Performance',
  //   icon: <TrendingUpOutlined />,
  // },
]

const Sidebar = ({ user, isSidebarOpen, setisSidebarOpen, isNonMobile, drawerWith }) => {
  const [active, setActive] = useState('')
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()

  useEffect(() => {
    setActive(pathname.substring(1))
  }, [pathname])

  return (
    <Box component={'nav'}>
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setisSidebarOpen(false)}
          variant={'persistent'}
          anchor={'left'}
          sx={{
            width: drawerWith,
            '& .MuiDrawer-paper': {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              borderWidth: isNonMobile ? '0' : '2px',
              width: drawerWith,
            },
          }}
        >
          <Box width={'100%'}>
            <Box m={'1.5rem 2rem 2rem 3rem'}>
              <FlexBetween color={theme.palette.secondary.main}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'0.5rem'}
                >
                  <Typography
                    variant={'h4'}
                    fontWeight={'bold'}
                  >
                    MovieHeroAdmin
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setisSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon, link }) => {
                if (!icon) {
                  return (
                    <Typography
                      key={text}
                      m={'2.25rem 0 1rem 3rem'}
                    >
                      {text}
                    </Typography>
                  )
                }
                const lcText = link.toLowerCase()
                return (
                  <ListItem
                    key={text}
                    disablePadding
                  >
                    <ListItemButton
                      sx={{
                        backgroundColor:
                          active === lcText ? theme.palette.secondary[300] : 'transparent',
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                      onClick={() => {
                        navigate(`/${lcText}`)
                        setActive(lcText)
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: '2rem',
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && <ChevronRightOutlined sx={{ ml: 'auto' }} />}
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Box>

          <Box
            width={'100%'}
            position={'absolute'}
            bottom='0'
            paddingBottom={'20px'}
          >
            <Divider />
            <FlexBetween
              textTransform={'none'}
              gap={'1rem'}
              p={'1.5rem 1.2rem 0 3rem'}
            >
              <Box
                component={'img'}
                alt={'profile img'}
                src={imageUrl}
                width={'40px'}
                height={'40px'}
                borderRadius={'50%'}
                sx={{ objectFit: 'cover' }}
              />
              <Box textAlign={'left'}>
                <Typography
                  fontWeight={'bold'}
                  fontSize={'0.9rem'}
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontWeight={'bold'}
                  fontSize={'0.8rem'}
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <SettingsOutlined sx={{ color: theme.palette.secondary[300], fontSize: '25px' }} />
            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default Sidebar
