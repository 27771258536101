import { useForm, Controller } from 'react-hook-form'
import { TextField, Select, Box, MenuItem, Button, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PersonAdd } from '@mui/icons-material'
import { useDispatch } from 'react-redux'

import { useAddUserMutation } from 'store/slices/user/userApiSlice'
import { pushUser } from 'store/slices/user/userSlice'

import MultipleSelectCheckmarks from 'components/select'
import Header from 'components/header'
import { notify } from 'utils/notify'

const AddUser = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      roles: ['USER'],
    },
  })
  const [addUser, { isSuccess, isLoading, isError, errors: userErrors }] = useAddUserMutation()

  const onSubmit = async (data) => {
    try {
      const userData = await addUser(data).unwrap()
      reset()
      console.log(userData)

      dispatch(pushUser({ user: userData }))
      notify(`Пользователь ${userData.name} добавлен!`, 'success')
      navigate('/users', { replace: true })
    } catch (error) {
      const { data } = error
      notify(data?.message, 'error')
      data?.errors?.map((err) =>
        setError(err.path, {
          message: err.msg,
        }),
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header
        title='Добавить новго пользователя'
        subTitle='Заполните форму'
        action={
          <Button
            variant='contained'
            startIcon={<PersonAdd />}
            size='large'
            sx={{
              color: `${theme.palette.secondary[200]}`,
            }}
            type='submit'
          >
            Создать
          </Button>
        }
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 3 }}>
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <TextField
              required
              error={errors['name'] ? true : false}
              helperText={errors['name']?.message}
              id='outlined-required'
              label='Имя пользователя'
              {...field}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <TextField
              required
              error={errors['email'] ? true : false}
              helperText={errors['email']?.message}
              id='outlined-required'
              label='Email'
              {...field}
            />
          )}
        />
        <Controller
          name='roles'
          control={control}
          render={({ field }) => {
            return (
              <MultipleSelectCheckmarks
                options={['USER', 'CUSTOMER', 'ADMIN', 'SUPER_ADMIN']}
                tag='Роли'
                error={errors['roles'] ? true : false}
                helperText={errors['roles']?.message}
                {...field}
              />
            )
          }}
        />
        {/* <Controller
          name='roles'
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label='Роли'
              defaultValue='USER'
              // multiple
              // value={roles}
            >
              <MenuItem value={'USER'}>USER</MenuItem>
              <MenuItem value={'CUSTOMER'}>CUSTOMER</MenuItem>
              <MenuItem value={'ADMIN'}>ADMIN</MenuItem>
              <MenuItem value={'SUPER_ADMIN'}>SUPER_ADMIN</MenuItem>
            </Select>
          )}
        /> */}
      </Box>
    </form>
  )
}

export default AddUser
