import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'

import { useGetRatesQuery } from 'store/slices/rate/rateApiSlice'

import Header from 'components/header'
import RateCard from 'components/rate'

const Rates = () => {
  const isNonMobile = useMediaQuery('(min-width: 1000px)')
  const { data, isLoading, isError } = useGetRatesQuery()
  return (
    <>
      <Header
        title='Тарифы'
        subTitle='Страница с тарифами (Дорабатывается)'
      />
      {isLoading && <Typography variant='p'>Loading...</Typography>}
      {isError && <Typography variant='p'>Error!!!</Typography>}
      {!isLoading && !isError && data && (
        <Box
          display='grid'
          gridTemplateColumns='repeat(4, minmax(0, 1fr))'
          justifyContent='space-between'
          rowGap='20px'
          columnGap='1.33%'
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          {data.rates.map((el) => (
            <RateCard
              key={el._id}
              {...el}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default Rates
