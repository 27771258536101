import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom'

import { useGetMeQuery } from 'store/slices/auth/authApiSlice'
import { setUser } from 'store/slices/auth/authSlice'

import { notify } from 'utils/notify'

import Layout from 'scenes/layout'
import Dashboard from 'scenes/dashboard'
import NotFound from 'scenes/not-found'
import Rates from 'scenes/rates'
import Auth from 'scenes/auth'

import Users from 'scenes/users'
import AddUser from 'scenes/add-user'
import EditUser from 'scenes/edit-user'

import { themeSettings } from 'theme'
import { ProtectedRoute } from 'components/protected-route'

const App = () => {
  const { data, isError, isLoading, isSuccess, error } = useGetMeQuery()
  const { mode } = useSelector((store) => store.theme)
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
  const dispatch = useDispatch()

  useEffect(() => {
    if (isError) {
      notify(`${error?.data?.message}`, 'error')
      dispatch(setUser({ user: null }))
    }
    if (isSuccess) {
      dispatch(setUser({ user: data }))
    }
  }, [isError, isSuccess])

  return (
    <div className='app'>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path='/'
              element={
                <Navigate
                  to='/dashboard'
                  replace
                />
              }
            />
            <Route element={<ProtectedRoute />}>
              <Route element={<Layout />}>
                <Route
                  path='/dashboard'
                  element={<Dashboard />}
                />
                <Route path='/users'>
                  <Route
                    path='add'
                    element={<AddUser />}
                  />
                  <Route
                    path=':id'
                    element={<EditUser />}
                  />
                  <Route
                    index
                    element={<Users />}
                  />
                </Route>
                <Route
                  path='/rates'
                  element={<Rates />}
                />

                <Route
                  path='*'
                  element={<NotFound />}
                />
              </Route>
            </Route>
            <Route
              path='/auth'
              element={<Auth />}
            />
          </Routes>
        </ThemeProvider>
      </Router>
    </div>
  )
}

export default App
