import { useState } from 'react'
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
  Notifications,
} from '@mui/icons-material'
import {
  AppBar,
  Button,
  IconButton,
  InputBase,
  Toolbar,
  useTheme,
  Box,
  Typography,
  Menu,
  MenuItem,
  Fade,
  Badge,
} from '@mui/material'
import { useDispatch } from 'react-redux'

import FlexBetween from 'components/flexbetweenbox'
import { switchMode } from 'store/slices/theme/themeSlice'
import imageUrl from 'assets/profile.jpeg'

const Navbar = ({ user, isSidebarOpen, setisSidebarOpen }) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar
      sx={{
        position: 'static',
        background: 'none',
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
        }}
      >
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setisSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius={'9px'}
            gap={'3rem'}
            p={'0.1rem 0.5rem 0.1rem 1.5rem'}
          >
            <InputBase placeholder='Search...' />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap={'1.5rem'}>
          <IconButton onClick={() => dispatch(switchMode())}>
            {theme.palette.mode === 'dark' ? (
              <LightModeOutlined sx={{ fontSize: '25px' }} />
            ) : (
              <DarkModeOutlined sx={{ fontSize: '25px' }} />
            )}
          </IconButton>
          <IconButton onClick={() => console.log('click on notifications icon')}>
            <Badge
              badgeContent={11}
              color='secondary'
              max={9}
            >
              <Notifications sx={{ fontSize: '25px' }} />
            </Badge>
          </IconButton>
          <FlexBetween>
            <Button
              id='fade-button'
              aria-controls={isOpen ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={isOpen ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textTransform: 'none',
                gap: '1rem',
              }}
            >
              <Box
                component={'img'}
                alt={'profile img'}
                src={imageUrl}
                width={'32px'}
                height={'32px'}
                borderRadius={'50%'}
                sx={{ objectFit: 'cover' }}
              />
              <Box textAlign={'left'}>
                <Typography
                  fontWeight={'bold'}
                  fontSize={'0.85rem'}
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontWeight={'bold'}
                  fontSize={'0.75rem'}
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: '25px' }}
              />
            </Button>
            <Menu
              id='fade-menu'
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={handleClose}>Изменить</MenuItem>
              <MenuItem onClick={handleClose}>Выйти</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
