import React, { useEffect } from 'react'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { Box, Button, useTheme } from '@mui/material'
import { MoreVert, Delete, Security, FileCopy, Settings, PersonAdd } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useGetUsersQuery } from 'store/slices/user/userApiSlice'
import { setUsers } from 'store/slices/user/userSlice'

import Header from 'components/header'

const Users = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data, isLoading, isError, isSuccess } = useGetUsersQuery()
  const columns = [
    {
      field: 'name',
      headerName: 'Имя',
      flex: 0.2,
    },
    {
      field: 'email',
      headerName: 'Почта',
      flex: 0.2,
    },
    // {
    //   field: 'phoneNumber',
    //   headerName: 'Phone number',
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     return params.value.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2-$3-$4')
    //   },
    // }
    {
      field: 'roles',
      headerName: 'Роль',
      renderCell: (params) => {
        const role = params.value[params.value.length - 1]
        return <strong>{role}</strong>
      },
      flex: 0.3,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Settings />}
          label='Изменить'
          onClick={() => navigate(`${params.id}`)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Delete color='error' />}
          label='Удалить'
          onClick={() => console.log(`Удалить ${params.id}`)}
          showInMenu
        />,
      ],
    },
  ]

  useEffect(() => {
    if (isError) {
      dispatch(setUsers([]))
    }
    if (isSuccess) {
      dispatch(setUsers({ users: data }))
    }
  }, [isSuccess, isError])

  return (
    <>
      <Header
        title='Пользователи'
        subTitle='Список всех ползователей'
        action={
          <Button
            variant='outlined'
            startIcon={<PersonAdd />}
            sx={{
              color: `${theme.palette.primary[200]}`,
            }}
            onClick={() => navigate('add')}
          >
            Добавить
          </Button>
        }
      />
      <Box
        height='75dvh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            // borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.primary.light,
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: 'none',
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          columns={columns}
          rows={data || []}
          getRowId={(property) => property._id}
        />
      </Box>
    </>
  )
}

export default Users
