import React, { useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
} from '@mui/material'
import { ToggleOn, ToggleOff } from '@mui/icons-material'

const RateCard = ({ title, subTitle, adventages, price, _id, type, show }) => {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <Card
      sx={{
        backgroundImage: 'none',
        backgroundColor: theme.palette.background.alt,
        borderRadius: '0.55rem',
      }}
    >
      <CardContent>
        <Typography
          fontSize={'14px'}
          color={theme.palette.secondary[200]}
          gutterBottom
        >
          Тариф
        </Typography>
        <Typography
          variant='h5'
          component='div'
        >
          {title}
        </Typography>
        <Typography
          sx={{ mb: '1.5rem' }}
          color={theme.palette.secondary[400]}
        >
          {Number(price).toFixed(2)}₽
        </Typography>
        <Typography
          variant='body1'
          color={theme.palette.secondary[300]}
        >
          {type}
        </Typography>
        <Typography variant='body2'>{subTitle}</Typography>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => setIsExpanded((prev) => !prev)}
          variant='primary'
          size='small'
        >
          Еще
        </Button>
        <Button
          onClick={() => setIsExpanded((prev) => !prev)}
          variant='primary'
          size='small'
        >
          {show ? (
            <ToggleOn
              sx={{
                color: show ? theme.palette.secondary[500] : theme.palette.primary[200],
              }}
              fontSize='large'
            />
          ) : (
            <ToggleOff
              sx={{
                color: show ? theme.palette.secondary[500] : theme.palette.primary[200],
              }}
              fontSize='large'
            />
          )}
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout='auto'
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>ID: {_id}</Typography>
          {adventages.map((el, i) => {
            return <Typography key={i}>- {el}</Typography>
          })}
          {/* <Typography>Yearly Sales This Year: {stat[0].yearlySalesTotal}</Typography>
          <Typography>Yearly Units Sold This Year: {stat[0].yearlyTotalSoldUnits}</Typography> */}
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default RateCard
