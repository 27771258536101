import { apiSlice } from '../../api/apiSlice'

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/auth/login',
        method: 'POST',
        body: data,
      }),
    }),
    logOut: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'GET',
      }),
    }),
    getMe: builder.query({
      query: () => ({
        url: '/auth/me',
      }),
    }),
  }),
})

export const { useLoginMutation, useGetMeQuery, useLogOutMutation } = authApiSlice
