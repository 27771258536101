import React from 'react'

import Header from 'components/header'

const Dashboard = () => {
  return (
    <>
      <Header
        title='Админ Панель'
        subTitle='В разработке'
      />
    </>
  )
}

export default Dashboard
