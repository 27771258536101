import { createSlice } from '@reduxjs/toolkit'

const initialState = { users: [] }

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      const { users } = action.payload
      state.users = users
    },
    pushUser: (state, action) => {
      const { user } = action.payload
      state.users = [user, ...state.users]
    },
  },
})

export const { setUsers, pushUser } = userSlice.actions

export default userSlice.reducer

export const selectUsers = (state) => state.user.users
