import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { FormHelperText } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const MultipleSelectCheckmarks = React.forwardRef(
  ({ options, tag, onChange, value, error, helperText }, ref) => {
    const handleChange = (event) => {
      onChange(event.target.value)
    }

    return (
      <FormControl sx={{ width: 300 }}>
        <InputLabel
          id='demo-multiple-checkbox-label'
          error={error}
        >
          {tag}
        </InputLabel>
        <Select
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label='Tag' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          ref={ref}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              <Checkbox checked={value.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </FormControl>
    )
  },
)

export default MultipleSelectCheckmarks
