import Header from 'components/header'
import React from 'react'

const NotFound = () => {
  return (
    <>
      <Header
        title='Страница не найдена'
        subTitle='Похоже страница которую Вы ищете была удалена или переехала на другой адрес'
      />
    </>
  )
}

export default NotFound
