import { useEffect } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useGetMeQuery } from 'store/slices/auth/authApiSlice'
import { selectCurrentUser, setUser } from 'store/slices/auth/authSlice'
import { notify } from 'utils/notify'
import { hasAccess } from 'utils/hasAccess'

import NoAccess from 'components/no-access'

export const ProtectedRoute = () => {
  const user = useSelector(selectCurrentUser)

  const location = useLocation()

  const state = {
    from: location,
  }

  return user ? (
    hasAccess(user?.roles) ? (
      <Outlet />
    ) : (
      <NoAccess />
    )
  ) : (
    <Navigate
      to='/auth'
      state={state}
      replace={true}
    />
  )
}
