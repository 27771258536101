import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import NavBar from 'components/navbar'
import Sidebar from 'components/sidebar'

const Layout = () => {
  const isNonMobile = useMediaQuery('(min-width: 600px)')
  const [isSidebarOpen, setisSidebarOpen] = useState(true)
  // const { userId } = useSelector((store) => store.user)
  const data = { name: 'Николай' }

  return (
    <Box
      display={isNonMobile ? 'flex' : 'block'}
      width={'100%'}
      height={'100%'}
    >
      <Sidebar
        user={data || {}}
        isSidebarOpen={isSidebarOpen}
        setisSidebarOpen={setisSidebarOpen}
        isNonMobile={isNonMobile}
        drawerWith={'250px'}
      />
      <Box flexGrow={'1'}>
        <NavBar
          user={data || {}}
          isSidebarOpen={isSidebarOpen}
          setisSidebarOpen={setisSidebarOpen}
        />
        <Box p='1.5rem 2.5rem'>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default Layout
